
export const lang = {
	lang: '中文',
	// 倒计时
	days: 'days',
	hours: 'hours',
	minutes: 'minutes',
	seconds: 'seconds',
	plan: 'plan',
	plan_status: 'plan status',
	time: 'time',
	i_initiated: 'I initiated',
	i_participated_in: 'I participated in',
	info: 'More',
	wt_config: 'Equipment Required (A Fee May Apply to the Presenter)',
	wt_img: 'Upload the Stage Setting Diagram',
	jmz_tips: 'Would you like the organizing commitee to provide rhythm section musicians for you?',
	jmz_yes: 'Yes,Please noie that a fee may be requlred, paryable directly to theaccompamying musicians during the congress.',
	jmz_self: 'Wll you be anangin Mr the rhthm section yourseite provice a band introductlon.',
	jmz_dahui: 'Upload the lead sheet or arrangement for the combo or ensemble.',
	group_jj: 'Introduction of the Presenting/ndividual',
	group_img: 'Photo of the Presenter(s)',
	pay_now: 'Pay the Reviewing Fee',
	delete: 'Delete',
	// 通知
}

